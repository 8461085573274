import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`OHS 1-1-1-1 to a 1RM`}</p>
    <p>{`Power Snatch 1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`2018 Regional WOD 4`}</strong></p>
    <p>{`2 Rounds of:`}</p>
    <p>{`10 Snatches (175/125)`}</p>
    <p>{`12 Bar Facing Burpees`}</p>
    <p>{`then, 2 Rounds of:`}</p>
    <p>{`10 Snatches (115/75)`}</p>
    <p>{`12 Bar Facing Burpees`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`(Scale 1st 2 Rounds to 80% 1RM Power Snatch and 2nd 2 Rounds to 55% if
needed)`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`Bonus Test:`}</em></strong>{` 1 Mile Assault Bike for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      